$gray: #eeeeee;
$gray-border: #cccccc;
$black: #000000;
$white: #ffffff;
$yellow: yellow;
$red: #dc3545;
$yellow-orange: #fbca03;
$blue: #00a3ff;
$purple: #532882;
$green: #28a745;
$orange: orange;
$querysize: 600px;
.app {
  background-color: $gray;
  // height: 1000vh;
  .margrintop20 {
    margin-top: 20px;
  }
  .margrinbottom20 {
    margin-bottom: 20px;
  }
  .margrintop10 {
    margin-top: 10px;
  }
  .margrinbottom10 {
    margin-bottom: 10px;
  }
  .ant-layout-header {
    background-color: unset;
  }
  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 60px;
    left: 0px;
    top: 0px;

    background: $black;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.05);
    img {
      display: flex;
    }
  }
  main {
    padding: 10px 20% 0 20%;
    .container {
      border-radius: 6px;
      background-color: $white;
      padding: 0 20px 0 20px;
      min-width: 300px;
      min-height: 100vh;
      .title {
        background-color: $gray;
        color: $black;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 6px;
        font-weight: 900;
      }
      .tag-yellow {
        padding: 4px 16px;
        width: 88px;
        height: 30px;
        background: $yellow-orange;
        border-radius: 32px;
        line-height: 50px;
      }
      // @media only screen and (max-width: $querysize) {
      //   .tag-yellow {
      //     line-height: 50px;
      //   }
      // }
      .bank-row {
        .bank-slot {
          .copy-element {
            cursor: pointer;
          }
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 16px;
          gap: 20px;

          max-width: 346.67px;
          height: 112px;

          background: $white;
          border: 1px solid $gray-border;
          border-radius: 8px;
          flex: none;
          order: 0;
          flex-grow: 1;

          .bank-image {
            img {
              position: absolute;
            }
            display: inline-block;
            width: 70px;
          }
          .bank-details {
            display: inline-block;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }
          .bank-copy {
            span {
              position: absolute;
              top: 5px;
              right: 5px;
            }
          }
        }
        @media only screen and (max-width: 930px) {
          .bank-slot {
            max-width: 100%;
          }
        }
      
      }
      .history-empty {
        border: 1px solid $gray-border;
        border-radius: 8px;
      }
      .alertbox {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        min-height: 40px;
        border-radius: 4px;
        flex: none;
        order: 0;
        flex-grow: 0;
        &-red {
          @extend .alertbox;
          color: $red;
          background: rgba(220, 53, 69, 0.1);
        }
        &-yellow {
          @extend .alertbox;
          color: $yellow-orange;
          background: rgba(251, 202, 3, 0.1);
        }
        &-blue {
          @extend .alertbox;
          color: $blue;
          background: rgba(0, 163, 255, 0.1);
        }
      }
      .qrcode-action {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        gap: 8px;
        margin-top: 10px;
        max-width: 347px;
        height: 29px;

        border-radius: 4px;
        flex: none;
        order: 1;
        flex-grow: 0;
        cursor: pointer;
        color: $white;
        &-scb {
          @extend .qrcode-action;
          background: $purple;
        }
        &-kbank {
          @extend .qrcode-action;
          background: $green;
        }
        &-truem {
          @extend .qrcode-action;
          background: $orange;
        }
      }
      @media only screen and (max-width: 930px) {
        .qrcode-action {
          max-width: 100%;
        }
      }
      .card-transaction {
        border-radius: 8px 8px 8px 8px;
        margin-bottom: 20px;
        border: 1px solid $gray;
        .title-transaction {
          position: relative;
          width: 100%;
          height: 40px;
          background: $gray;

          .title-text {
            position: absolute;
            left: 10px;
            top: 10px;
          }
          .title-date {
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }
        .details-transaction {
          padding: 10px;
          &-right {
            text-align: right;
            .noti-success-text {
              color: $green;
            }
            .noti-success {
              color: $white;
              padding: 4px 16px;
              width: 67px;
              height: 30px;
              background: $green;
              border-radius: 4px;
              display: inline-block;
            }
            .noti-notsuccess {
              color: $white;
              padding: 4px 16px;
              width: 67px;
              height: 30px;
              background: $red;
              border-radius: 4px;
              display: inline-block;
            }
          }
          &-left {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            label {
              width: 80px;
              display: inline-block;
            }
            span {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }
   
  }
  @media only screen and (max-width: $querysize) {
    main {
      padding: 0;
    }
  }
  footer {
    max-height: 50px;
    background: #222222;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 0.7rem;
    .footer-line-top {
      color: #ffffff;
    }
    .footer-line-bottom {
      color: #ffffff;
    }
    .footer-line-bottom-yellow {
      color: $yellow-orange;
    }
  }
}
.modalInputQr {
  .title {
    margin-top: 15px;
    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 140%;
    }
  }
  .ant-row {
    display: flex;
    justify-content: center;
  }
  .numberCreateQrCode {
    width: 100%;
    background-color: $gray;
  }
  
  .createQrCodeBtn {
    width: 100%;
    color: $white;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    &-scb {
      @extend .createQrCodeBtn;
      background-color: $purple;
    }
    &-kbank {
      @extend .createQrCodeBtn;
      background-color: $green;
    }
    &-truem {
      @extend .createQrCodeBtn;
      background-color: $orange;
    }
  }
  ::placeholder {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }
}
.modalShowQr {
  .title {
    margin-top: 15px;
    text-align: center;
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 140%;
    }
  }
  .qr-details {
    margin-top: 15px;
    text-align: center;
  }
  .user-details {
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    .text-center {
      text-align: center;
    }
  }
}
.modalSuccess {
  .ant-result-title {
    max-width: 390px;
  }
}
